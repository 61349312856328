import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { size, spacing, color, ensembles, composers } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgEN from "../images/50_years_en.png"
import imgDE from "../images/50_years_de.png"
import imgFR from "../images/50_years_fr.png"
import imgIT from "../images/50_years_it.png"

const Block = styled.div`
  background-color: ${color.COLOR2};

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  & div:first-of-type {
    flex: 0 1 350px;
  }
  & p {
    flex: 1 1;
    padding: 5em;
    margin-bottom: 0;
  }
  & a {
    color: white;
  }
  @media (max-width: 930px) {
    & div:first-of-type {
      max-width: 100%;
    }
    flex-direction: column;
  }
`

const CallToAction = styled(Block)`
  margin-top: 4em;
  & div:first-of-type {
    height: 150px;
  }
  & p {
    padding: 2em;
  }
  & div:nth-of-type(2) {
    flex: 0 1 100px;
    & img {
      width: 50px;
      height: 50px;
      margin: auto;
    }
  }

  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;

    & div:nth-of-type(2) {
      transition: transform 0.5s ease-in-out;
      transform: translateX(20px);
    }
  }
  @media (max-width: 930px) {
    div:nth-of-type(2) {
      display: none;
    }
  }
`

const CustomLink = styled.a`
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
  }
`

const CustomLinkDisabled = styled.span`
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
`

// const WettbewerbPage = () => {
//   const intl = useIntl()
//   return (
//     <>
//       <Layout title={intl.formatMessage({ id: "wettbewerb" }) + " 2023"}>
//         <SEO
//           lang={intl.locale}
//           title={intl.formatMessage({ id: "wettbewerb" }) + " 2023"}
//           keywords={[`gatsby`, `application`, `react`]}
//         />
//         <p>
//           <FormattedMessage
//             id="wettbewerb-2023-text"
//             values={{
//               a1: msg => (
//                 <a
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   href={`/download/${intl.formatMessage({
//                     id: "reglement-url",
//                   })}.pdf`}
//                   css={css`
//                     color: white;
//                     transition: opacity 0.25s;
//                     &:hover {
//                       opacity: 0.5;
//                     }
//                   `}
//                 >
//                   {msg}
//                 </a>
//               ),
//               a2: msg => (
//                 <a
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   href="/download/competition-2023-ablauf.pdf"
//                   css={css`
//                     color: white;
//                     transition: opacity 0.25s;
//                     &:hover {
//                       opacity: 0.5;
//                     }
//                   `}
//                 >
//                   {msg}
//                 </a>
//               ),
//               b: msg => <b>{msg}</b>,
//               u: msg => <u>{msg}</u>,
//               br: (
//                 <>
//                   <br />
//                 </>
//               ),
//             }}
//           />
//         </p>

//         {/* <p>
//         <FormattedMessage
//             id="atena-info"
//             values={{
//               a1: msg => (
//                 <a
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   href={`https://www.concoursgeneve.ch/section/competitions/string_quartet_2023/`}
//                   css={css`
//                     color: white;
//                     transition: opacity 0.25s;
//                     &:hover {
//                       opacity: 0.5;
//                     }
//                   `}
//                 >
//                   {msg}
//                 </a>
//               ),
//               b: msg => <b>{msg}</b>,
//               u: msg => <u>{msg}</u>,
//               br: (
//                 <>
//                   <br />
//                 </>
//               ),
//             }}
//           />
//         </p> */}
//       </Layout>
//     </>
//   )
// }

const WettbewerbPage = () => {
  const intl = useIntl()
  const imageMap = {
    en: imgEN,
    de: imgDE,
    fr: imgFR,
    it: imgIT,
  }
  const currentImage = imageMap[intl.locale] || imgEN

  return (
    <>
      <Layout title={intl.formatMessage({ id: "wettbewerb" }) + " 2024"}>
        <img
          src={currentImage}
          width={150}
          alt="Orpheus Music Logo"
          css={css`
            display: block;
            margin: -40px auto 2em auto;
            width: 100px;
          `}
        />
        <SEO
          lang={intl.locale}
          title={intl.formatMessage({ id: "wettbewerb" }) + " 2024"}
          keywords={[`gatsby`, `application`, `react`]}
        />
        <p>
          <FormattedMessage
            id="wettbewerb-2024-text"
            values={{
              a1: msg => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/download/${intl.formatMessage({
                    id: "reglement-url",
                  })}.pdf`}
                  css={css`
                    color: white;
                    transition: opacity 0.25s;
                    &:hover {
                      opacity: 0.5;
                    }
                  `}
                >
                  {msg}
                </a>
              ),
              a2: msg => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.muvac.com/de/competition/orpheus-swiss-chamber-music-competition-zy8ep6k1"
                  css={css`
                    color: white;
                    transition: opacity 0.25s;
                    &:hover {
                      opacity: 0.5;
                    }
                  `}
                >
                  {msg}
                </a>
              ),
              b: msg => <b>{msg}</b>,
              u: msg => <u>{msg}</u>,
              br: (
                <>
                  <br />
                </>
              ),
            }}
          />
        </p>

        <h2><FormattedMessage id="programm-bern-titel" /></h2>
        <p>
        <FormattedMessage
            id="programm-bern-text"
            values={{
              a1: msg => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://ticketino.com`}
                  css={css`
                    color: white;
                    transition: opacity 0.25s;
                    &:hover {
                      opacity: 0.5;
                    }
                  `}
                >
                  {msg}
                </a>
              ),
              b: msg => <b>{msg}</b>,
              u: msg => <u>{msg}</u>,
              br: (
                <>
                  <br />
                </>
              ),
            }}
          />
        </p>
      </Layout>
    </>
  )
}

export default WettbewerbPage
